import { createAsyncThunk } from '@reduxjs/toolkit';
import { WTSService } from 'services/wts/wtsApi';
import { sleep } from 'features/wts/utils/sleep';
import { CreateFixationOrReserveParams } from 'models/params/wts/metalSale/createReserve';
import { ReservesListParamsSchema } from 'models/params/wts/metalSale/reservesList';
import { ReservesListEntity } from 'models/api/wts/metalSale/reservesList';
import { ErrorEntity } from 'models/api/wts/metalSale/error';
import { parseDataBySchema } from 'utils/parseDataBySchema';
import { FixationEntity } from 'models/api/wts/metalSale/fixation';
import { ApiReserveMetalFormEntity } from 'models/api/wts/metalSale/reserveMetalForm';
import {
  ReserveAmountParamsSchema,
  ReserveDiscountParamsSchema,
  ReserveMetalFormParamsSchema,
} from 'models/params/wts/metalSale/reserveMetalForm';
import { SuccessEntity } from 'models/api/wts/common/success';
import {
  ApproveParams,
  CancelReserveParams,
  DeleteReserveParams,
  ExtendParams,
} from 'models/params/wts/metalSale/reserveAction';
import { normalizeReserveMetalForm } from 'ducks/wts/metalSale/utils';
import { VerificationEntity } from 'models/api/wts/common/verification';
import { ManageFormParamsSchema } from 'models/params/wts/metalSale/manageForm';
import { ManageFormEntity } from 'models/api/wts/metalSale/manageForm';
import { closableMessage as antdMessage } from 'components/ClosableMessage';
import dayjs from 'dayjs';
import { wtsAnalytics } from 'features/wts/metal-sale/wtsAnalytics';
import { FixationListEntity } from 'models/api/wts/metalSale/fixationList';
import {
  APPROVE_RESERVE_API_PATH,
  CANCEL_RESERVE_API_PATH,
  COMPANY_GROUPS_API_PATH,
  CREATE_FIXATION_API_PATH,
  CREATE_RESERVE_API_PATH,
  CREATE_TRIGGER_FIXATION_API_PATH,
  DELETE_FIXATION_API_PATH,
  DELETE_RESERVE_API_PATH,
  DELETE_TRIGGER_FIXATION_API_PATH,
  EXTEND_RESERVE_API_PATH,
  FIX_PRICE_API_PATH,
  FIXATION_FORM_RESERVE_API_PATH,
  FIXATION_LIST_API_PATH,
  INIT_FEATURES_BUY_API_PATH,
  INIT_FIX_FORM_API_PATH,
  INIT_MANAGE_FORM_API_PATH,
  KITCO_PRICE_API_PATH,
  RAW_TYPES_API_PATH,
  RESERVES_LIST_API_PATH,
  SPOT_PRICE_API_PATH,
  UPDATE_FIXATION_API_PATH,
  UPDATE_RESERVE_AMOUNT_API_PATH,
  UPDATE_RESERVE_API_PATH,
  UPDATE_RESERVE_DISCOUNT_API_PATH,
  VERIFICATION_AUTH_API_PATH,
} from 'ducks/wts/metalSale/consts';
import { CompanyGroupListEntity } from 'models/api/wts/common/companyGroup';
import { RawTypeListEntity } from 'models/api/wts/metalSale/rawType';
import { FixationListParamsSchema } from 'models/params/wts/metalSale/fixationList';
import { InitFixFormParamsSchema } from 'models/params/wts/metalSale/initFixForm';
import { FixationInitFormEntity } from 'models/api/wts/metalSale/fixationInitForm';
import { FixationMethodsEntity } from 'models/api/wts/metalSale/fixationMethods';
import { FixOrKitcoPriceParamsSchema, SpotPriceParamsSchema } from 'models/params/wts/metalSale/fixationMethods';
import { FixationDeleteEntity } from 'models/api/wts/metalSale/fixationDelete';
import { createUrlFormPath } from 'utils/createUrlFormPath';
import { ConnectionSetupEntity } from 'models/api/wts/common/connectionSetup';
import { AuthIdParam } from 'models/params/wts/common/connectionSetup';
import { InitBuyFeaturesEntity } from 'models/api/wts/metalSale/initBuyData';
import { CreateTriggerParamsSchema } from 'models/params/wts/common/createTrigger';
import { TriggerSchemaEntity } from 'models/api/wts/common/triggerSchema';
import { DeleteTriggerParamsSchema } from 'models/params/wts/common/deleteTrigger';
import { onCloseWS, setupFailed, setupOnReconnect } from './slice';
import { isMailActionTrigger } from '../utils';

export const setupWTSService = createAsyncThunk<ConnectionSetupEntity, AuthIdParam>(
  'wtsAuth/setupWTSService',
  async (userId, { rejectWithValue, dispatch }) => {
    const data = await WTSService.setup(userId, dispatch, { onCloseWS, setupFailed, setupOnReconnect });
    if (data && !data.error) {
      return data;
    } else {
      return rejectWithValue(data);
    }
  }
);

export const destroyWTSService = createAsyncThunk('wtsAuth/destroyWTSService', async () => {
  return WTSService.destroy();
});

// пагинатор со списком фиксаций всех групп компаний пользователя
export const getFixationList = createAsyncThunk<FixationListEntity, FixationListParamsSchema | undefined>(
  'wts/getFixationList',
  async (search, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: FIXATION_LIST_API_PATH,
      body: search,
    });

    const [result, zodError] = parseDataBySchema(FixationListEntity, data);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    antdMessage.error('Не удалось получить список поставок');

    return rejectWithValue(apiError || zodError || null);
  }
);

// список групп компаний к которым имеет доступ пользователь
export const getCompanies = createAsyncThunk<CompanyGroupListEntity, undefined>(
  'wts/getCompanies',
  async (_, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: COMPANY_GROUPS_API_PATH,
    });

    const [result, zodError] = parseDataBySchema(CompanyGroupListEntity, data);

    if (result) {
      return result;
    }

    antdMessage.error('Не удалось получить список компаний');

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    return rejectWithValue(apiError || zodError || null);
  }
);

// получение списка типов сырья выбранной группы компаний
export const getRawTypes = createAsyncThunk<RawTypeListEntity, string>(
  'wts/getRawTypes',
  async (buyCompanyGroup, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: RAW_TYPES_API_PATH,
      body: { buyCompanyGroup },
    });

    const [result, zodError] = parseDataBySchema(RawTypeListEntity, data);

    if (result) {
      return result;
    }

    antdMessage.error('Не удалось получить список типов сырья');

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    return rejectWithValue(apiError || zodError || null);
  }
);

// получение начальных данных для формы создания поставки (лимиты, список типов сырья и т.п.)
export const getManageForm = createAsyncThunk<ManageFormEntity, ManageFormParamsSchema>(
  'wts/getManageForm',
  async (payload, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: INIT_MANAGE_FORM_API_PATH,
      body: payload,
      headers: [],
    });

    const [result, zodError] = parseDataBySchema(ManageFormEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);
    antdMessage.error('Не удалось получить данные формы');
    return rejectWithValue(apiError || zodError || null);
  }
);

// создание новой поставки и присвоение ей уникального идентификатора
// (WTS Backend создает поставку в базе и возвращает структуру поставки с уже присвоенными id для каждого элемента)
export const addSupply = createAsyncThunk<FixationEntity, CreateFixationOrReserveParams>(
  'wts/addSupply',
  async (payload, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: CREATE_FIXATION_API_PATH,
      body: payload,
    });
    const [result, zodError] = parseDataBySchema(FixationEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error('Не удалось создать поставку');

    return rejectWithValue(apiError || zodError || null);
  }
);

// oбновление данных поставки (идемпотентно, все элементы которые не были преданы удаляются, отличается от создания поставки
// тем что у элементов могут быть уже присвоенные им id (у всех или только части))
export const editSupply = createAsyncThunk<FixationEntity, CreateFixationOrReserveParams>(
  'wts/editSupply',
  async (payload, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: UPDATE_FIXATION_API_PATH,
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(FixationEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error('Не удалось изменить поставку');

    return rejectWithValue(apiError || zodError || null);
  }
);

// инициализация фиксации - набор данных для настройки каждой
// строчки формы с фиксациями
export const getInitFixationForm = createAsyncThunk<FixationInitFormEntity, InitFixFormParamsSchema>(
  'wts/getInitFixationForm',
  async ({ delay = 0, ...payload }, { rejectWithValue }) => {
    if (delay) {
      await sleep(delay);
    }
    const response = await WTSService.callV2({
      path: INIT_FIX_FORM_API_PATH,
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(FixationInitFormEntity, response);

    if (result) {
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error('Не удалось получить настройки формы');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const createTrigger = createAsyncThunk<TriggerSchemaEntity & { id: string }, CreateTriggerParamsSchema>(
  'wts/createTrigger',
  async ({ id, ...payload }: CreateTriggerParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(CREATE_TRIGGER_FIXATION_API_PATH, { id }),
      body: payload,
      headers: [],
    });

    const [result, zodError] = parseDataBySchema(TriggerSchemaEntity, response);

    if (result) {
      antdMessage.success(isMailActionTrigger(payload.actionType) ? 'Уведомление создано' : 'Заявка создана');
      return { id, ...result };
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);
    antdMessage.error(
      apiError?.message || `Не удалось создать ${isMailActionTrigger(payload.actionType) ? 'уведомление' : 'заявку'}`
    );

    return rejectWithValue(apiError || zodError || null);
  }
);

export const deleteTrigger = createAsyncThunk(
  'wts/deleteTrigger',
  async ({ actionType, ...values }: DeleteTriggerParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(DELETE_TRIGGER_FIXATION_API_PATH, { id: values.publicId }),
      method: 'DELETE',
      headers: [],
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result?.success) {
      antdMessage.success(isMailActionTrigger(actionType) ? 'Уведомление удалено' : 'Заявка отменена');
      return values;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(
      apiError?.message || isMailActionTrigger(actionType)
        ? 'Не удалось удалить уведомление'
        : 'Не удалось отменить заявку'
    );

    return rejectWithValue(apiError || zodError || null);
  }
);

// com.wts.fix_price => /api/fixation/fix - фиксация ценового предложения по фиксингу - нажатие на кнопку фиксации
// (может прийти ошибка фиксации)
export const fixThePrice = createAsyncThunk(
  'wts/fixThePrice',
  async (payload: FixOrKitcoPriceParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: FIX_PRICE_API_PATH,
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(FixationMethodsEntity, response);

    if (result) {
      return result.data;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error('Не удалось зафиксировать изменения');

    return rejectWithValue(apiError || zodError || null);
  }
);

// com.wts.fix_kitco_price -> POST /api/fixation/kitko
export const fixTheKitcoPrice = createAsyncThunk(
  'wts/fixTheKitcoPrice',
  async (payload: FixOrKitcoPriceParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: KITCO_PRICE_API_PATH,
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(FixationMethodsEntity, response);

    if (result) {
      return result.data;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error('Не удалось зафиксировать изменения');

    return rejectWithValue(apiError || zodError || null);
  }
);

// com.wts.fix_spot_price -> /api/fix-spot-price
export const fixTheSpotPrice = createAsyncThunk(
  'wts/fixTheSpotPrice',
  async ({ submitTime, ...payload }: SpotPriceParamsSchema, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: SPOT_PRICE_API_PATH,
      body: payload,
    });

    const [result, zodError] = parseDataBySchema(FixationMethodsEntity, response);

    if (result) {
      wtsAnalytics.spotSuccess(dayjs().diff(submitTime, 'second', true));
      return result.data;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error('Не удалось зафиксировать изменения');

    return rejectWithValue(apiError || zodError || null);
  }
);

// com.wts.spot_price.*** где *** -> 123e4567_e89b_12d3_a457_426655440000
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribeSpotPrice = createAsyncThunk<any, any>(
  'wts/getSpotPrice',
  async ({ fixationContentId, getAction }, { rejectWithValue, dispatch }) => {
    try {
      return await WTSService.subscribeWithDispatch(`com.wts.spot_price.${fixationContentId}`, getAction, dispatch);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unsubscribeSpotPrice = createAsyncThunk<any, any>(
  'wts/unsubscribeSpotPrice',
  async (topic, { rejectWithValue }) => {
    try {
      return await WTSService.unsubscribe(topic);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const verificationCheck = createAsyncThunk(`wtsAuth/verification`, async (_, { rejectWithValue }) => {
  const data = await WTSService.callV2({
    path: VERIFICATION_AUTH_API_PATH,
  });
  const [result, zodError] = parseDataBySchema(VerificationEntity, data);

  if (result) {
    return {
      verification: {
        isVerified: result.features.buy.canViewSection,
        role: result.role,
        canCreatePriceFixation: result.features.buy.canCreatePriceFixation,
      },
    };
  }

  const [apiError] = parseDataBySchema(ErrorEntity, data);
  antdMessage.error(apiError?.message || 'Ошибка верификации');
  return rejectWithValue(apiError || zodError || null);
});

// удаление поставки со статусом 1 - черновик, com.wts.fixation.delete
export const deleteFixation = createAsyncThunk<FixationDeleteEntity, string>(
  `wts/deleteFixation`,
  async (id, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: DELETE_FIXATION_API_PATH,
      body: { id },
    });

    const [result, zodError] = parseDataBySchema(FixationDeleteEntity, response);

    if (result) {
      antdMessage.success(`Фиксация успешно удалена`);
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(`Не удалось удалить фиксацию`);

    return rejectWithValue(apiError || zodError || null);
  }
);

export const ping = createAsyncThunk(`wts/ping`, async (_, { rejectWithValue }) => {
  try {
    const data = await WTSService.call(`com.wts.common.ping`);

    const diff = dayjs().diff(data.now, 'millisecond', true);
    // eslint-disable-next-line no-console
    console.log([
      '[DEBUG] HealthCheck timeSync',
      {
        status: dayjs().diff(data.now, 'second', true) <= 2,
        pingStatus: data.status,
        context: {
          diff,
          local: dayjs().format(),
          server: data.now,
        },
      },
    ]);

    return { ...data, diff: Math.floor(diff / 1000) };
  } catch (error) {
    return rejectWithValue(error);
  }
});

// debug subscribe
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscribeDebug = createAsyncThunk<any, any>(`wts/debug`, async (topic, { rejectWithValue }) => {
  try {
    return await WTSService.subscribe(topic);
  } catch (error) {
    rejectWithValue(error);
  }
});

// пагинатор со списком разрешений
export const getReserveList = createAsyncThunk(
  'wts/getReserveList',
  async (payload: ReservesListParamsSchema | undefined, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: RESERVES_LIST_API_PATH, body: payload });

    const [result, zodError] = parseDataBySchema(ReservesListEntity, data);

    if (result) {
      return result.paginator;
    }

    antdMessage.error('Не удалось получить список разрешений');

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    return rejectWithValue(apiError || zodError || null);
  }
);

export const addReserve = createAsyncThunk<FixationEntity, CreateFixationOrReserveParams>(
  'wts/addReserve',
  async (payload: CreateFixationOrReserveParams, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: CREATE_RESERVE_API_PATH,
      body: payload,
      headers: [],
    });

    if (response) {
      return response;
    }

    antdMessage.error('Не удалось создать разрешение');
    const [apiError] = parseDataBySchema(ErrorEntity, response);

    return rejectWithValue(apiError || null);
  }
);

export const editReserve = createAsyncThunk<FixationEntity, CreateFixationOrReserveParams>(
  'wts/editReserve',
  async (payload: CreateFixationOrReserveParams, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: UPDATE_RESERVE_API_PATH,
      body: payload,
      headers: [],
    });

    if (response) {
      return response;
    }

    antdMessage.error('Не удалось изменить разрешение');
    const [apiError] = parseDataBySchema(ErrorEntity, response);

    return rejectWithValue(apiError || null);
  }
);

export const getReserveMetalForm = createAsyncThunk(
  'wts/getReserveMetalForm',
  async (payload: ReserveMetalFormParamsSchema, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: FIXATION_FORM_RESERVE_API_PATH, body: payload });

    const [result, zodError] = parseDataBySchema(ApiReserveMetalFormEntity, data);

    if (result) {
      return normalizeReserveMetalForm(result);
    }

    antdMessage.error('Не удалось получить разрешение');

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    return rejectWithValue(apiError || zodError || null);
  }
);

export const updateReserveDiscount = createAsyncThunk(
  'wts/updateReserveDiscount',
  async ({ fixationContentId, discount, metal }: ReserveDiscountParamsSchema, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: createUrlFormPath(UPDATE_RESERVE_DISCOUNT_API_PATH, { fixationContentId }),
      body: { discount },
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, data);

    if (result) {
      antdMessage.success(
        `${discount > 0 ? 'Премия успешно обновлена' : 'Дисконт успешно обновлен'}: ${metal.signature}`
      );
      return;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    antdMessage.error(
      apiError?.message || `Не удалось обновить ${discount > 0 ? 'премию' : 'дисконт'}: ${metal.signature}`
    );
    return rejectWithValue(apiError || zodError || null);
  }
);

export const updateReserveAmount = createAsyncThunk(
  'wts/updateReserveAmount',
  async ({ fixationContentId, amount, metal }: ReserveAmountParamsSchema, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: createUrlFormPath(UPDATE_RESERVE_AMOUNT_API_PATH, { fixationContentId }),
      body: { amount },
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, data);

    if (result) {
      antdMessage.success(`Масса успешно обновлена: ${metal.signature}`);
      return;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    antdMessage.error(apiError?.message || `Не удалось обновить массу: ${metal.signature}`);
    return rejectWithValue(apiError || zodError || null);
  }
);

export const approveReserve = createAsyncThunk<SuccessEntity, ApproveParams>(
  'wts/approveReserve',
  async ({ id, expiresAt }: ApproveParams, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(APPROVE_RESERVE_API_PATH, { id }),
      body: { expiresAt },
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result) {
      antdMessage.success('Разрешение отправлено поставщику');
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(response?.message || 'Не удалось применить');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const extendReserve = createAsyncThunk<SuccessEntity, ExtendParams>(
  'wts/extendReserve',
  async ({ id, expiresAt }: ExtendParams, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(EXTEND_RESERVE_API_PATH, { id }),
      body: { expiresAt },
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result) {
      antdMessage.success('Успешно изменен');
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);

    antdMessage.error(response?.message || 'Не удалось применить');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const cancelReserve = createAsyncThunk<SuccessEntity, CancelReserveParams>(
  'wts/cancelReserve',
  async ({ id }: CancelReserveParams, { rejectWithValue }) => {
    const response = await WTSService.callV2({
      path: createUrlFormPath(CANCEL_RESERVE_API_PATH, { id }),
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, response);

    if (result) {
      antdMessage.success('Разрешение отозвано у поставщика');
      return result;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, response);
    antdMessage.error(response?.message || 'Не удалось отозвать разрешение у поставщика');

    return rejectWithValue(apiError || zodError || null);
  }
);

export const deleteReserve = createAsyncThunk(
  'wts/deleteReserve',
  async ({ id }: DeleteReserveParams, { rejectWithValue }) => {
    const data = await WTSService.callV2({
      path: createUrlFormPath(DELETE_RESERVE_API_PATH, { id }),
      method: 'DELETE',
    });

    const [result, zodError] = parseDataBySchema(SuccessEntity, data);

    if (result) {
      antdMessage.success('Разрешение перенесено в фиксацию');
      return;
    }

    const [apiError] = parseDataBySchema(ErrorEntity, data);
    antdMessage.error(apiError?.message || 'Не удалось перенести разрешение в фиксацию');
    return rejectWithValue(apiError || zodError || null);
  }
);

export const getInitBuyFeatures = createAsyncThunk<InitBuyFeaturesEntity, undefined>(
  'wts/getInitBuyFeatures',
  async (_, { rejectWithValue }) => {
    const data = await WTSService.callV2({ path: INIT_FEATURES_BUY_API_PATH, method: 'GET' });

    const [result, zodError] = parseDataBySchema(InitBuyFeaturesEntity, data);

    if (result) {
      return result;
    }

    antdMessage.error('Не удалось получить список инициализационных данных');

    const [apiError] = parseDataBySchema(ErrorEntity, data);

    return rejectWithValue(apiError || zodError || null);
  }
);
